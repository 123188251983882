body {
  @include iota-type($type-base);

  background-color: $color-ui-grey-lightest;
  color: $color-ui-black;
  font-display: swap;
  font-family: $font-family-serif;
  font-weight: $font-weight-light;
  padding:
    constant(safe-area-inset-top) constant(safe-area-inset-right)
    constant(safe-area-inset-bottom) constant(safe-area-inset-left);
  position: relative;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}
