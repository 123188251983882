@import '../../styles/component-helper';

.c-visualizer {
  display: none;

  @media screen and (prefers-reduced-motion: no-preference) {
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 10;
  }
}
