.u-bg-attachment-fixed {
  background-attachment: fixed;
}

.u-bg-attachment-local {
  background-attachment: local;
}

.u-bg-repeat {
  background-repeat: repeat;
}

.u-bg-repeat-x {
  background-repeat: repeat-x;
}

.u-bg-repeat-y {
  background-repeat: repeat-y;
}

.u-bg-contain {
  background-size: contain;
}

.u-bg-cover {
  background-size: cover;
}

.u-bg-pos-b {
  background-position: bottom;
}

.u-bg-pos-bl {
  background-position: bottom left;
}

.u-bg-pos-br {
  background-position: bottom right;
}

.u-bg-pos-l {
  background-position: left;
}

.u-bg-pos-r {
  background-position: right;
}

.u-bg-pos-t {
  background-position: top;
}

.u-bg-pos-tl {
  background-position: top left;
}

.u-bg-pos-tr {
  background-position: top right;
}
