.u-box-shadow {
  box-shadow: $box-shadow;
}

.u-box-shadow-b {
  box-shadow: $box-shadow-b;
}

.u-box-shadow-l {
  box-shadow: $box-shadow-l;
}

.u-box-shadow-r {
  box-shadow: $box-shadow-r;
}

.u-box-shadow-t {
  box-shadow: $box-shadow-t;
}

.u-box-shadow-inset {
  box-shadow: inset $box-shadow;
}

.u-box-shadow-inset-b {
  box-shadow: inset $box-shadow-b;
}

.u-box-shadow-inset-l {
  box-shadow: inset $box-shadow-l;
}

.u-box-shadow-inset-r {
  box-shadow: inset $box-shadow-r;
}

.u-box-shadow-inset-t {
  box-shadow: inset $box-shadow-t;
}
