$iota-objs-list-block: true;
$iota-objs-list-inline: true;
$iota-objs-list-span: true;
$iota-objs-list-gutter-default: $iota-global-gutter-default;
$iota-objs-list-gutter-extra: (
  compact: 0
);
$iota-objs-list-namespace: 'list';
$iota-objs-list-item-name: 'item';
$iota-objs-list-block-name: 'block';
$iota-objs-list-inline-name: 'inline';
$iota-objs-list-span-name: 'span';

@import 'node_modules/iotacss/objects/list';
